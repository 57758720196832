import React from "react";
import {
  Card,
  CardBody,
  Button,
} from "reactstrap";
import { Link, navigate } from "gatsby";
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon
} from "react-share";
import defaultImage from '../../assets/images/xella-default-small.png';
import "./styles.scss";

const CardComponent = ({
  id,
  url,
  btnText,
  socialMediaImage,
  btnClass,
  cardClass,
  cardBodyClass,
  imageClass,
  serviceInfo,
  image,
  title,
  subtitle,
  desc,
  onClick,
  discounts,
  isGatsbyImage,
  imageObjectFit
}) => {

  if (discounts) {
    return (
      <>
        <Card className={cardClass ? `${cardClass}  bg-white` : "bg-white"}>
          <div className='card-img-container d-grid'>
            {
              isGatsbyImage
                ? <GatsbyImage
                  image={getImage(image)}
                  alt=""
                  objectFit={imageObjectFit ? imageObjectFit : 'cover'}
                  layout="fixed"
                />
                : image
                  ? <div
                    id={imageClass ? `${imageClass}` : ''}
                    className={'card-image'}
                    style={{ backgroundImage: `url(${image})` }}
                  />
                  : <div
                    id={imageClass ? `${imageClass}` : ''}
                    className={'card-image-default'}
                  />
            }
          </div>
          <CardBody className={cardBodyClass ? `${cardBodyClass} card-text-container` : "card-text-container"}>
            {
              serviceInfo
                ? <div className="services-info-container">{serviceInfo}</div>
                : null
            }
            <div className='discounts-card-title-container'>
              <div className='card-title'>
                <h4 className="font-weight-bold mb-3">{title}</h4>
              </div>
              <div
                className='card-description'
                dangerouslySetInnerHTML={{ __html: desc }}
              />
            </div>
          </CardBody>
        </Card>
        <div className={"discounts-button-top shadow"}>
          <Link
            className="nav-link bg-white d-flex py-3 br-5 no-underline-link"
            to={url ? url : ""}
          >
            <Button
              outline
              onClick={onClick}
              className={`${btnClass
                ? `${btnClass} btn btn-lg shadow card-btn-style margin-0-auto text-uppercase`
                : "btn btn-lg shadow card-btn-style margin-0-auto text-uppercase"}`}
            >
              {`${btnText ? btnText : "виж повече"}`}
            </Button>
          </Link>
        </div>
      </>
    )
  } else {
    return (
      <Card className={cardClass ? `${cardClass} shadow bg-white` : "shadow bg-white"}>
        <div className='card-img-container d-flex'>
          {
            isGatsbyImage
              ? <GatsbyImage
                image={getImage(image)}
                alt=""
                objectFit={imageObjectFit ? imageObjectFit : 'cover'}
                layout="fixed"
                className='mx-auto'
              />
              : image
                ? <div
                  id={imageClass ? `${imageClass}` : ''}
                  className={'card-image'}
                  style={{ backgroundImage: `url(${image})` }}
                />
                : <div
                  id={imageClass ? `${imageClass}` : ''}
                  className={'card-image-default'}
                />
          }
        </div>
        <CardBody className={cardBodyClass ? `${cardBodyClass} card-text-container` : "card-text-container"}>
          {
            serviceInfo
              ? <div className="services-info-container">{serviceInfo}</div>
              : null
          }
          <div className='card-title-container'>
            <div className='card-title'>
              <h4 className="mb-3 font-weight-bold">{title}</h4>
              {
                subtitle
                  ? <h5 className="mb-3 color-gray">на {subtitle}</h5>
                  : null
              }

            </div>
            {
              desc !== undefined && 
              <div
              className='card-description'
              dangerouslySetInnerHTML={{ __html: desc }}
              />
            }
          {
            url === '/presentations' &&  
            <div>
              <FacebookShareButton
                imageURL={defaultImage}
                url={`https://building-companion.bg/presentations`}
              >
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              <LinkedinShareButton
                className="margin-left5"
                url={`https://building-companion.bg/presentations`}
              >
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>
            </div>
          }
          </div>

          <div
            role='none'
            className="nav-link no-underline-link"
            onClick={() => url && navigate(url)}
          >
            <Button
              outline
              onClick={onClick}
              className={`${btnClass
                ? `${btnClass} btn btn-lg shadow card-btn-style text-uppercase`
                : "btn btn-lg shadow card-btn-style text-uppercase"}`}
            >
              {`${btnText ? btnText : "виж повече"}`}
            </Button>
          </div>
        </CardBody>
      </Card>
    );
  }
};

export default CardComponent;
